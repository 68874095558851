html {
  overflow: auto;
  height: 100%;
}

html,
body,
#root {
  height: 100%;
  /* enable smooth scrolling on iOS */
  -webkit-overflow-scrolling: touch;
  font-family: 'Figtree', sans-serif;
  font-size: 14px;
  background-color: #fcfcfc;
  color: rgba(0, 46, 70, 0.85);
}

.ant-modal-header,
.ant-form-item-label {
  font-family: 'Figtree', sans-serif;
  color: #475569;
}
